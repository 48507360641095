import { addDays, addMinutes, formatDistanceToNow, setHours, setMinutes } from 'date-fns';
import { format, toDate } from 'date-fns-tz';
import { enCA, frCA } from 'date-fns/locale';
import i18n from '../../i18n';

export const formatDateOnlyWithWeekday = (date: Date, locale?: string): string => {
  return getUtcDateOnly(date).toLocaleDateString(locale, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const formatDateLong = (date: Date, locale?: string): string | undefined => {
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const formatDateShortMonth = (date: Date, locale?: string): string | undefined => {
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const toIsoDateOnly = (date: Date | string): string => {
  const isoDateStr = typeof date === 'string' ? date.toString() : date.toISOString();
  const [isoDateOnly] = isoDateStr.split('T'); // ignore time if full date was given
  return isoDateOnly;
};

export const isoDateOnlyToDate = (date: string): Date => {
  const [year, month, day] = toIsoDateOnly(date).split('-');
  return new Date(Number(year), Number(month) - 1, Number(day));
};

export const isoDateOnlyToDateUtc = (date: string): Date => {
  const [year, month, day] = toIsoDateOnly(date).split('-');
  return new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)));
};

export const formatDateOnlyStandard = (date: Date, locale = 'fr'): string => getUtcDateOnly(date).toLocaleDateString(locale);

export const formatDateOnly = (date: Date, locale?: string): string => {
  const frLocale = 'fr';
  const enSouthAfricaLocale = 'en-ZA';
  return locale === frLocale || locale === undefined ? formatDateOnlyStandard(date) : formatDateOnlyStandard(date, enSouthAfricaLocale);
};

export const isValidTime = (time: string): boolean => /[0-9]{2}:[0-9]{2}/.test(time);

export const splitTime = (time?: string | null): [number, number] => {
  if (time == null) return [0, 0];
  const [hours, minutes] = (time || '').split(':');
  return [+hours, +minutes];
};

export const newDateFromTimeString = (time?: string | null, date?: Date | string | null, endOfDay = false): Date => {
  const [hours, minutes] = splitTime(time);
  if (date && time) {
    const _date =
      typeof date === 'string'
        ? date
        : `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date
            .getUTCDate()
            .toString()
            .padStart(2, '0')}`;
    return addDays(toDate(`${_date} ${time}`, { timeZone: 'America/Montreal' }), endOfDay ? 1 : 0);
  }
  return addDays(new Date(0, 0, 0, hours, minutes), endOfDay ? 1 : 0);
};

export const formatMinutesToTimeString = (minutes: number, separator = ':'): string =>
  `${String(Math.floor(minutes / 60)).padStart(2, '0')}${separator}${String(minutes % 60).padStart(2, '0')}`;

export const formatMinutesToHourString = (minutes: number, locale?: string): string => {
  const separator: string = locale === 'fr' ? ',' : '.';
  return (Math.fround(minutes / 60) || 0).toFixed(2).replace('.', separator);
};

export const formatMinutesToHoursDecimal = (minutes: number): number => Math.round((minutes / 60) * 1e2) / 1e2 || 0;

export const formatDate = (date: Date, formatStr: string): string => format(date, formatStr, { timeZone: 'America/Montreal' });

export const formatTime = (date: Date): string => {
  try {
    return formatDate(date, 'HH:mm');
  } catch (e) {
    return '';
  }
};

export const formatTimeFromUtcString = (date: string | null | undefined): string | null => {
  if (!date) return null;
  return format(Date.parse(date), 'HH:mm');
};

export const newDateFromTimeStringAndDuration = (
  time: string,
  durationInMinutes: number,
  date?: Date | string | null,
  endOfDay = false,
): Date | undefined => {
  const dateFrom = newDateFromTimeString(time, date, endOfDay);
  if (!dateFrom) return undefined;
  return addMinutes(dateFrom, durationInMinutes);
};

export const computedTo = (from: string, durationInMinutes: number, date: Date): string | undefined => {
  const dateFrom = newDateFromTimeString(from, date);
  if (!dateFrom) return undefined;
  return formatTime(addMinutes(dateFrom, durationInMinutes));
};

export const getUtcDateOnly = (date: Date): Date => new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);

export const getDistanceToNow = (date: Date): string => {
  const frLocale = 'fr';
  return formatDistanceToNow(date, {
    addSuffix: true,
    locale: i18n.resolvedLanguage === frLocale ? frCA : enCA,
  });
};

export const isBetweenDates = (dateToValidate: Date, minDate: Date, maxDate: Date | undefined): boolean => {
  const minDateTime = minDate.getTime();
  const maxDateTime = !!maxDate ? maxDate.getTime() : Number.MAX_VALUE;
  const dateToValidateTime = dateToValidate.getTime();
  return dateToValidateTime >= minDateTime && dateToValidateTime <= maxDateTime;
};

export const minutesToMilliseconds = (minutes: number): number => minutes * 60 * 1000;

export const roundUpToNextQuarterHour = (date: Date): Date => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const roundedMinutes = Math.ceil(minutes / 15) * 15;

  if (roundedMinutes >= 60) {
    hours += 1;
    minutes = 0;
  } else {
    minutes = roundedMinutes;
  }

  let result = setMinutes(date, minutes);
  result = setHours(result, hours);
  return result;
};
