import {
  BooleanSendRequirementTypeEnum,
  CancelReasonEnum,
  DispatchingAlertArchivedJustificationEnum,
  OrdreDeTravailPostponementReasonEnum,
} from '../Models/models';

const postponementReason = {
  [OrdreDeTravailPostponementReasonEnum.ClientEquipmentBreakdown]: 'Client: Equipment Breakdown',
  [OrdreDeTravailPostponementReasonEnum.ClientMechanicalBreakdown]: 'Client: Mechanical Breakdown',
  [OrdreDeTravailPostponementReasonEnum.ClientUnknownReason]: 'Client: Unknown Reason',
  [OrdreDeTravailPostponementReasonEnum.ClientRepresentativeError]: 'Client: Representative Error',
  [OrdreDeTravailPostponementReasonEnum.ClientEquipmentShortage]: 'Client: Equipment Shortage',
  [OrdreDeTravailPostponementReasonEnum.ClientLaborShortage]: 'Client: Labor Shortage',
  [OrdreDeTravailPostponementReasonEnum.ClientCraneShortage]: 'Client: Crane Shortage',
  [OrdreDeTravailPostponementReasonEnum.ClientIncorrectDate]: 'Client: Incorrect Date',
  [OrdreDeTravailPostponementReasonEnum.ClientWrongCrane]: 'Client: Wrong Crane',
  [OrdreDeTravailPostponementReasonEnum.ClientDidNotReceiveOccupancyPermit]: 'Client: Did Not Receive Occupancy Permit',
  [OrdreDeTravailPostponementReasonEnum.ClientWeather]: 'Client: Weather',
  [OrdreDeTravailPostponementReasonEnum.ClientTransportation]: 'Client: Transportation',
  [OrdreDeTravailPostponementReasonEnum.GuayEquipmentBreakdown]: 'Guay: Equipment Breakdown',
  [OrdreDeTravailPostponementReasonEnum.GuayEquipmentShortage]: 'Guay: Equipment Shortage',
  [OrdreDeTravailPostponementReasonEnum.GuayLaborShortage]: 'Guay: Labor Shortage',
};

const cancelReason = {
  [CancelReasonEnum.ClientEquipmentBreakdown]: 'Client: Equipment Breakdown',
  [CancelReasonEnum.ClientMechanicalBreakdown]: 'Client: Mechanical Breakdown',
  [CancelReasonEnum.ClientUnknownReason]: 'Client: Unknown Reason',
  [CancelReasonEnum.ClientRepresentativeError]: 'Client: Representative Error',
  [CancelReasonEnum.ClientEquipmentShortage]: 'Client: Equipment Shortage',
  [CancelReasonEnum.ClientLaborShortage]: 'Client: Labor Shortage',
  [CancelReasonEnum.ClientCraneShortage]: 'Client: Crane Shortage',
  [CancelReasonEnum.ClientIncorrectDate]: 'Client: Incorrect Date',
  [CancelReasonEnum.ClientWrongCrane]: 'Client: Wrong Crane',
  [CancelReasonEnum.ClientDidNotReceiveOccupancyPermit]: 'Client: Did Not Receive Occupancy Permit',
  [CancelReasonEnum.ClientWeather]: 'Client: Weather',
  [CancelReasonEnum.ClientTransportation]: 'Client: Transportation',
  [CancelReasonEnum.GuayEquipmentBreakdown]: 'Guay: Equipment Breakdown',
  [CancelReasonEnum.GuayEquipmentShortage]: 'Guay: Equipment Shortage',
  [CancelReasonEnum.GuayLaborShortage]: 'Guay: Labor Shortage',
};

const sendRequirementTypes = {
  [BooleanSendRequirementTypeEnum.ContinuityConfirmed]: 'OT confirmation',
  [BooleanSendRequirementTypeEnum.ServiceCallStatus]: 'Service call transferred',
  [BooleanSendRequirementTypeEnum.AccessoryIdentification]: 'Accessory Identification',
};

export const alertJustifications = {
  trainingJustifications: {
    [DispatchingAlertArchivedJustificationEnum.TrainingWillBeCompleted]: 'The training will be completed before the start of the call',
    [DispatchingAlertArchivedJustificationEnum.TrainingWasCompleted]: 'Already trained, not entered into the system',
    [DispatchingAlertArchivedJustificationEnum.TrainingNotRequired]: 'Training is not required',
    [DispatchingAlertArchivedJustificationEnum.EmployeeAccompanied]: 'The employee will be accompanied',
  },
  craneCapacityBelowJustifications: {
    [DispatchingAlertArchivedJustificationEnum.TonnageNotInContract]: 'The requested tonnage is not included in the annual contract',
    [DispatchingAlertArchivedJustificationEnum.InterchangeableEquipment]: 'Equipment is interchangeable',
    [DispatchingAlertArchivedJustificationEnum.EquipmentMeetsNeed]:
      'Required tonnage not available, but the chosen equipment meets the need',
  },
};

const alert = {
  alerts: 'Alerts',
  alertCenter: 'Alerts Center',
  active: 'Active',
  archived: 'Archived',
  archive: 'Archive',
  archivedBy: 'Archived by',
  high: 'Majors',
  low: 'Minors',
  see: 'See',
  commentPlaceholder: 'Enter a comment...',
  ...alertJustifications,
};

const transports = {
  transports: 'Transports',
  planned: 'Planned',
  unplanned: 'Unplanned',
  description: 'Description',
  supplier: 'Supplier',
  numeroDeCommandeAchat: '#CA',
  departure: 'Departure',
  plan: 'Plan',
  isRequired: '{{field}} is required.',
};

const incompatibility = {
  incompatibilities: 'Incompatibilities',
};

export const dispatch = {
  alert,
  transports,
  incompatibility,
  accessory_one: '{{count}} Accessory',
  accessory_other: '{{count}} Accessories',
  all: 'All',
  arrival: 'Arrival',
  arrivalTime: 'Arrival time',
  bare: 'Bare Crane',
  remark: 'Remark',
  description: 'Description',
  supplier: 'Supplier',
  assignation: 'Assignation',
  branches: 'Branches',
  client: 'Client',
  confirmSendAssignments: 'Confirm assignments to send',
  craneNumber: 'Unit number',
  departure: 'Departure',
  departureTime: 'Departure time',
  ready: 'Ready',
  readyTime: 'Ready time',
  requestModification: 'Request modification',
  estimatedDurationInHours: {
    label: 'Work duration',
    shortLabel: 'Duration',
    value_one: '{{count}} hour',
    value_other: '{{count}} hours',
  },
  filters: 'Filters',
  hideEmptyResource: 'Hide empty lines',
  hideUnchangedAssignments: 'Hide unchanged assignments',
  maximumWeightToLift: {
    label: 'Maximum weight to lift',
    value_one: '{{count}} lb',
    value_other: '{{count}} lbs',
  },
  noBranchSelected: 'No branch selected',
  only: 'Only',
  optimizationCanceled: 'Optimization canceled (5 minutes passed)',
  optimizationStarted: 'Optimization started',
  optimize: 'Optimize',
  permissionsOnAllBranches_send: 'You must have the right to edit all the selected branches to send OTs',
  permissionsOnAllBranches_optimize: 'You must have the right to edit all the selected branches to optimize OTs',
  cancelOptimization: 'Cancel optimization',
  ongoingOptimization: 'Ongoing optimization',
  applyingOptimization: 'Applying optimization',
  optimizationAppliedMessage: 'Ordres de travail and their continuity have been updated',
  selectedBranch: 'Selected Branch',
  selectedBranchTooltip: 'Select one branch only',
  interBranch: 'Inter-Branch',
  exportDailyAccessoriesReport: 'Export daily accessory report',
  exportDailyCraneAndAccessoriesReport: 'Export daily crane and accessory report',
  exportDailyAccessoriesReportTooltip: 'Only available if a single branch is selected',
  representative: 'Project manager',
  requiredCapacity: {
    label: 'Required capacity',
    shortLabel: 'Capacity',
    value_one: '{{count}} ton',
    value_other: '{{count}} tons',
    shortValue: '{{count}} T',
  },
  resetFilters: 'Reset filters',
  operatorCompetence: {
    pasUnGrutier: 'Not an operator',
    compagnon: 'Journeyman',
    apprenti1: 'Apprentice - 1',
    apprenti2: 'Apprentice - 2',
    apprenti3: 'Apprentice - 3',
    exception: 'Exception',
  },
  ordresDeTravail: 'Ordres de travail',
  craneOperators: 'Crane operators',
  operator: 'Operator',
  cranes: 'Cranes',
  crane: 'Crane',
  departureDate: 'Departure Date',
  newValue: 'New value',
  oldValue: 'Old value',
  resources: 'Resources',
  subcontracted: 'Subcontracted',
  various: 'Various',
  status: 'Status',
  requiredConfiguration: 'Required Configuration',
  selectAll: 'Select all',
  cancel: 'Cancel',
  confirm: 'Confirm',
  modified: 'Modified',
  loading: 'Loading',
  send: 'Send',
  transferOrdreDeTravail: 'Branch transfer',
  selectNewUnit: 'Select the new unit',
  branch: 'Branch',
  unit: 'Unit',
  branchInOptimization: 'Branch is currently in optimization. Transfer is not possible.',
  subcontract: 'Subcontract',
  selectSubcontractor: 'Please enter the name of the supplier and a description',
  subcontractor: 'Subcontractor',
  numeroDeCommandeAchat: '#CA',
  transfer: 'Transfer',
  postpone: 'Postpone',
  accept: 'Accept',
  refuse: 'Refuse',
  postponeOrdreDeTravail: 'Postpone OT',
  chooseNewWorkingDay: 'Select the new working day',
  date: 'Date',
  warnings: 'Warnings',
  missingTrainingRequirements: 'Missing training requirements',
  ordreDeTravailLinkPrefix: 'OT',
  workSite: {
    name: 'Work site',
    location: 'Work site location',
  },
  dragDropErrors: {
    noStartDate: 'The selected OT does not have a start date',
    noServiceCallId: 'The selected OT comes from the AS400 and cannot be modified',
    maxedCraneOperators: 'The maximum quantity of assigned crane operators has been reached',
    craneOperatorAlreadyAssigned: 'The crane operator is already assigned to this OT',
    employeeAlreadyAssigned: 'The employee is already assigned to this OT',
    otAlreadyHasCrane: 'The selected OT already has an assigned crane',
    invalidResource: 'Invalid resource',
    subcontracted: 'The selected OT is subcontracted and cannot be modified ',
    invalidBranch: 'Cannot move OT to another branch',
    unauthorized: 'The current user is not authorized to edit this resource',
    ongoing: 'The selected OT is ongoing',
    finished: 'The selected OT is finished',
    notRollingEquipment: 'The selected OT can only be assigned to a rolling equipment',
    cannotBeAssignedToRollingEquipment: 'The selected OT can not be assigned to a rolling equipment',
    cannotAssignToVarious: 'The selected OT cannot be assigned to various',
    notOperatorAssignable: 'Cannot assign operator on this OT',
    notEquipmentAssignable: 'Cannot assign equipment on this OT',
  },
  eventContextMenu: {
    add: 'Add',
    advance: 'Advance',
    edit: 'Edit',
    editAccessories: 'Edit accessories',
    locking: 'Locking',
    lockAll: 'Lock all',
    unlockAll: 'Unlock all',
    unassign: 'Unassign',
    postpone: 'Postpone',
    transfer: 'Branch transfer',
    cancel: 'Cancel OT',
    remove: 'Remove',
    continuity: 'Continuity',
    subcontract: 'Subcontract',
    removeSubcontractor: 'Remove subcontractor',
    replaceOperator: 'Replace main operator',
    sendToVarious: 'Send to various',
    generateOtNumber: 'Generate OT number',
    viewOT: 'View OT details',
    modifyPlanification: 'Modify Planification',
    requestModification: 'Return for modification',
  },
  cancelOrdreDeTravailModal: {
    title: 'Cancel OT',
    instruction: 'Please enter a reason.',
    label: 'Remark',
    close: 'Cancel',
    confirm: 'Cancel OT',
  },
  otGridCellContextMenu: {
    cancel: 'Cancel',
  },
  modifiedContinuity: 'Modified continuity',
  onlyThisContinuity: 'Only this continuity',
  allContinuity: 'All following continuities',
  unassignedOT: {
    title: 'Unassigned OTs',
    value_one: '{{count}} unassigned OT',
    value_other: '{{count}} unassigned OTs',
  },
  optimizedSolution: 'Optimized solution',
  optimizing: 'Optimizing',
  validation: {
    required: '*Required',
    arrivalTimeBeforeDepartureTime: 'Arrival time cannot be before departure time',
    readyTimeBeforeArrivalTime: 'Ready time cannot be before arrival time',
  },
  addToOTContinuity: 'Add continuity to OT',
  newContinuityMessage: 'A new continuity will be added for the selected period.',
  validateTimesTitle: 'Validate continuity hours',
  from: 'From',
  to: 'To',
  addContinuity: 'Add continuity',
  accessoriesModal: {
    assignedTo: 'Assigned To',
    notAssignedWarning: 'When this field is empty, the accessory is considered as assigned to the primary crane operator',
    title: 'Modify accessories',
    accessoryType: 'Accessory type',
    identification: 'Identification',
    accessoryGroup: 'Accessory',
    billing: 'Billing',
    quantity: 'Quantity',
    billable: 'Billable',
    cancel: 'Cancel',
    save: 'Save',
    select: 'Select',
    addRemark: 'Add Remark',
    hourly: 'Hourly',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
  },
  replaceOperatorModal: {
    title: 'Replace operator',
    operator: 'Operator',
    cancel: 'Cancel',
    save: 'Save',
    select: 'Select',
  },
  preferredOperatorsModal: {
    title: 'Modified preferred operators',
    operators: 'Operators',
    cancel: 'Cancel',
    save: 'Save',
    select: 'Select',
  },
  advanceOTModal: {
    title: 'Advance OT',
    content: 'Please select the new work day',
    cancel: 'Cancel',
    advance: 'Advance',
  },
  assignOperatorModal: {
    title: 'Assign {{operator}}',
    cancel: 'Cancel',
    assign: 'Assign',
    kind: 'Role',
    kindLabel: 'Please select a role for the resource',
    assignInfoLabel: 'Assignation information',
    startingPointLabel: 'Starting point',
    branchStartingPoint: 'Branch',
    siteStartingPoint: 'Work site',
    departureTime: 'Departure time',
    arrivalTime: 'Arrival time',
    readyDateTime: 'Ready time',
    updateContinuity: 'Assign for all continuity',
  },
  unavailabilitiesModal: {
    title: 'Edit unavailabilities',
    from: 'From',
    to: 'To',
    type: 'Type',
    comment: 'Comment',
    commentPlaceholder: 'Enter a comment...',
    select: 'Select',
    options: {
      permanentOnWorkSite: 'On Worksite',
      repairs: 'Garage',
      rented: 'Rented',
    },
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    errors: {
      required: 'Required',
      endBeforeStart: 'Cannot end before start',
    },
    readonlyTitle: 'Unavailabilities',
    close: 'Close',
    noUnavailabilities: 'No recorded unavailabilities',
  },
  noSubcontractingModal: {
    title: 'No subcontractor accepted',
    content: 'The representative did not accept subcontractors for this service call. Do you want to continue?',
    cancel: 'Cancel',
    continue: 'Continue',
  },
  lockingJustificationModal: {
    title: 'Justify the lock',
    content: 'Please enter a justification to lock the assignment',
    justification: 'Justification',
    cancel: 'Cancel',
    lock: 'Lock',
  },
  noSubcontracting: 'No subcontractor',
  noReplacement: 'No crane replacement',
  secondServing: 'Second serving',
  readonly: 'Readonly',
  family: 'Family',
  boomLength: 'Boom',
  jibLength: 'Jib',
  counterweight: 'Counterweight',
  offset: 'Offset',
  others: 'Others',
  unavailabilityPeriod: 'Period',
  unavailabilityType: 'Type',
  permanentOnWorkSite: 'On Worksite',
  repairs: 'Garage',
  rented: 'Rented',
  unavailabilityComment: 'Comment',
  unavailability: 'Unavailability',
  distance: 'Distance',
  travelTime: 'Travel time',
  branchEquipment: 'Branch Equipment',
  unitNumber: 'Crane number',
  otNumber: 'OT number',
  craneOperatorName: 'Crane Operator',
  serviceCallType: 'Service call type',
  pageNotFound: 'The page you’re looking for doesn’t exist.',
  confirmDepartureTimeChangeModal: {
    title: 'Change of departure time',
    message: 'Do you want to change the OT departure time for {{newTime}} (previously {{oldTime}})?',
  },
  possibleWrongDepartureDateTime: 'The departure date time could be wrong.',
  comment: 'Comment',
  requiredPostponementReason: 'Comment is required',
  postponedOT: 'Postponed OT',
  postponement: {
    value_one: '{{count}} Postponement',
    value_other: '{{count}} Postponements',
  },
  sendRequirements: 'Send requirements',
  sendRequirementTypes,
  postponementReason,
  cancelReason,
  unmetRequirements: {
    requirements: 'Send requirements:',
    continuityConfirmed: 'OT confirmation',
    isAssignedToVarious: 'send to various',
    hasMainEquipment: 'main equipment assignment',
    hasOperatorOrSubcontractor: 'any crane operator or subcontractor assignment',
    hasCraneOperator: 'any crane operator assignment',
    serviceCallStatus: 'reserved service call',
    operatorKind: '{{kind}} assignment',
    alert: 'alert resolution',
    departureDateTime: 'missing departure date',
    accessoryIdentification: 'accessory identification',
  },
  confirmOT: 'Confirm',
  confirmOrdreDeTravailModal: {
    title: 'Confirm ordre de travail',
    keepOT: 'Keep #OT',
    generateOT: 'Generate new #OT',
    otNumberLabel: 'Ordre de travail number',
    dateRange: 'Date range',
    today: 'Active day',
    personalizedDate: 'Personalized date',
    numberAutomaticallyGenerated:
      'A new OT number will be generated. You will potentially lose an existing OT number. Do you want to continue?',
  },
  assignmentContinuityModal: {
    title: 'Remove a send requirement',
    allExceptLast: 'All except last day',
    remove: 'Remove',
  },
  requiredType: 'Required type',
};
